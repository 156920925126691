<template>
	<!-- 解决方案-第三方账号 -->
	<div class="container">
		<GModule>
			<div class="condition-box">
				<div class="state-box">
					<p>项目</p>
					<a-dropdown>
					    <template #overlay>
					        <a-menu selectable @click="handleMenuClick">
								<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.title}}</a-menu-item>
					        </a-menu>
					    </template>
					    <a-button>
							{{stateIndex ? stateArr[stateIndex].title : '全部'}}
					        <DownOutlined />
					    </a-button>
					</a-dropdown>
				</div>
				<div>
					<a-button class="btn clo1">保存</a-button>
					<a-button class="btn clo2">导出</a-button>
				</div>
			</div>
			<a-table
				class="ttable" 
				sticky 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500,y:420 }" 
				bordered
				:pagination="false">
			</a-table>
			<div class="documentFt" style="margin-top: 10px;">
			  <a-pagination size="small" :total="50" />
			</div>
		</GModule>
	</div>
</template>

<script>
export default{
	data(){
		return{
			columnsHe: [{
			    title: '序号',
			    width: 100,
			    dataIndex: 'createTime',
			    key: 'createTime',
			    fixed: 'left',
			  }, {
			    title: '第三方名称',
			    width: 100,
			    dataIndex: 'name',
			    key: 'name',
			    fixed: 'left',
			  }, {
			    title: '注册地址',
			    width: 100,
			    dataIndex: 'name',
			    key: 'name',
			    fixed: 'left',
			  }, {
			    title: '注册完成后账号',
			    width: 120,
			    dataIndex: 'describe',
			    key: 'describe'
			  }, {
			    title: '登录密码',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }, {
			    title: '开发者ID',
			    width: 100,
			    dataIndex: 'name',
			    key: 'name',
			    fixed: 'left',
			  }, {
			    title: '开发者密码',
			    width: 120,
			    dataIndex: 'describe',
			    key: 'describe'
			  }, {
			    title: '原始ID',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }, {
			    title: '商户密钥',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }, {
			    title: '其他1',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }, {
			    title: '其他2',
			    width: 100,
			    dataIndex: 'status',
			    key: 'status'
			  }],
			  data: [{
			    key: '1',
			    name: 'John Brown',
			    age: 32,
			    address: 'New York No. 1 Lake Park',
			  }, {
			    key: '2',
			    name: 'Jim Green大家聚聚军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军',
			    age: 42,
			    address: 'London No. 1 Lake Park',
			  }, {
			    key: '3',
			    name: 'Joe Black',
			    age: 32,
			    images:[],
			    address: 'Sidney No. 1 Lake Park',
			  }, {
			    key: '4',
			    name: 'Jim Red',
			    age: 32,
			    address: 'London No. 2 Lake Park',
			  },{
			    key: '5',
			    name: 'John Brown',
			    age: 32,
			    address: 'New York No. 1 Lake Park',
			  }, {
			    key: '6',
			    name: 'Jim Green',
			    age: 42,
			    address: 'London No. 1 Lake Park',
			  }, {
			    key: '7',
			    name: 'Joe Black',
			    age: 32,
			    address: 'Sidney No. 1 Lake Park',
			  }, {
			    key: '8',
			    name: 'Jim Red',
			    age: 32,
			    address: 'London No. 2 Lake Park',
			  }],
			  stateArr:[
			  	{title:"全部"},
			      {title:"张三"},
			  ] ,
			  stateIndex:0,
		}
	},
	methods:{
		handleMenuClick(e){
			this.stateIndex = e.key
			console.log("选择",this.stateIndex)
		},
	}
}
</script>

<style scoped lang="scss">
.container{
	margin: 20px;
	padding: 0;
	overflow: hidden;
	.condition-box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 5px 0 10px 0;
		.state-box{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
			p{
				margin: 0 10px 0 20px;
			}
		}
		.btn{
			margin-left: 20px;
			margin-bottom: 5px;
		}
	}
}
</style>